import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import Img from 'gatsby-image'
import './tags.scss'

function renderPicture(picture) {
  if (!picture) {
    return null
  }

  return (
    <div className="image">
      <Img fixed={picture.childImageSharp.fixed} alt="" />
    </div>
  )
}

const TagsPage = ({ data }) => {
  const tags = data.allStrapiTag.nodes
    .filter(tag => tag.recipes.length > 0)
    .sort((a, b) => b.recipes.length - a.recipes.length)
    .map(({ tag, slug, recipes }) => (
      <div className="tag-container">
        <div className="tag">
          {renderPicture(recipes[0].picture)}
          <div className="text">
            <div className="title">{tag}</div>
            <div className="count">{recipes.length} st</div>
            <ul className="links">
              <li>
                <Link to={`/kategorier/${slug}`}>Slumpa</Link>
              </li>
              <li>
                <Link to={`/kategorier/${slug}/lista`}>Lista</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    ))

  return (
    <Layout title="Kategorier">
      <div className="tags-page subpage">
        <h1>Kategorier</h1>
        <div>
          Lista <Link to="/alla">alla recept</Link>.
        </div>
        <div className="tag-list">{tags}</div>
      </div>
    </Layout>
  )
}

export default TagsPage

export const pageQuery = graphql`
  query TagsQuery {
    allStrapiTag {
      nodes {
        tag
        slug
        recipes {
          id
          picture {
            childImageSharp {
              fixed(width: 70, height: 70, cropFocus: ATTENTION) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      }
    }
  }
`
